html,
body {
	height: 100%;
}
#root {
	width: 100%;
}
body {
	font-size: 14px;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-webkit-box-pack: center;
	justify-content: center;
	background-color: #ffffff;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell',
		'Helvetica Neue', sans-serif;
	font-weight: normal;
	font-style: normal;
	letter-spacing: 0.005em;
	line-height: 150%;
	color: #353b50;
}

.h2,
h2 {
	font-size: 16px;
	font-style: normal;
	font-weight: normal;
	line-height: 130%;
	color: #100722;
}
